<template>
  <router-link :to="url" class="navigation__group group">
		<div class="group__link">
			<div class="group__text">
				<Stack space="2">
					<Icon :icon="icon" color="dark" v-if="icon"></Icon>
					<p class="group__label">{{label}}</p>
				</Stack>
			</div>
		</div>
		<div class="group__dropdown">
			<slot></slot>
		</div>
	</router-link>
</template>

<script>
export default {
	props: {
		label: String,
		icon: String,
		url: String,
	},
	data(){
		return{
		}
	},
}

</script>

<style lang="scss" scoped>

.group{
	width: var(--navigation-group-width);
	padding: var(--navigation-group-padding);
	text-decoration: none;
	display: block;
}
.group__link{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
}
.group__text{
	display: flex;
	flex-direction: row;
}
.group__label{
	font-family: var(--navigation-group-label-font-family);
	font-size: var(--navigation-group-label-size);
	font-weight: var(--navigation-group-label-weight);
	color: var(--navigation-group-label-color);
	margin: var(--navigation-group-label-margin);
}
.group__dropdown{
	overflow: hidden;
	max-height: var(--navigation-group-dropdown-height-start);
	transition: var(--navigation-group-dropdown-transition);
	opacity: var(--navigation-group-dropdown-opacity-start);
	padding: var(--navigation-group-dropdown-padding);
}
.router-link-active .group__dropdown{
  max-height: var(--navigation-group-dropdown-height-end);
	opacity:var(--navigation-group-dropdown-opacity-end);
	margin-top: var(--navigation-group-dropdown-margin-top-end);
	margin-bottom: var(--navigation-group-dropdown-margin-bottom-end);
}

</style>

<!-- Used in : DS -->